import emotion.react.css
import csstype.*

enum class KC_Color (val v : Color) {
    BLACK(Color("#000000")),
    DARK(Color("#1C1C1C")),
    GRAY_5(Color("#F4F4F4")),
    WHITE(Color("#FFFFFF")),
    WHITE_T20(Color("rgba(255, 255, 255, 0.2)")),
    WHITE_T60(Color("rgba(255, 255, 255, 0.6)")),
    PURPLE(Color("#7F52FF")),
    ORANGE(Color("#FF6501")),
    LIGHT_B(Color("#515151")),
    VERY_LIGHT_B(Color("rgba(255, 255, 255, 0.05)")),
    TRANSPARENT(Color("rgba(0, 0, 0, 0)"))
}