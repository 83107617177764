fun formatMoment(value : Moment): String {
    return "${addLeadingZero(value.hour)}:${addLeadingZero(value.minute)}"
}

fun formatInterval(value : Interval): String {
    return "${formatMoment(value.first)}—${formatMoment(value.second)}"
}

fun addLeadingZero(value: Int): String {
    return if (value >= 10) { value.toString() } else { "0" + value.toString() }
}

fun momentToMinutes(moment : Moment): Int {
    return (moment.hour * 60 + moment.minute)
}

fun fitsInterval(moment : Moment, interval : Interval): Boolean {
    return (momentToMinutes(moment) >= momentToMinutes(interval.first)) && (momentToMinutes(moment) < momentToMinutes(interval.second))
}

fun beforeInterval(moment : Moment, interval : Interval): Boolean {
    return (momentToMinutes(moment) > momentToMinutes(interval.first))
}

fun duringOrAfterInterval(moment : Moment, interval : Interval): Boolean {
    return (momentToMinutes(interval.second) > momentToMinutes(moment))
}

fun afterInterval(moment : Moment, interval : Interval): Boolean {
    return (momentToMinutes(interval.first) >= momentToMinutes(moment))
}

fun tagFor(room : Room, slot : Slot): Tag {
    return when (slot) {
        is Slot.NerdRanch -> Tag.NERD_RANCH
        is Slot.AwsLab -> Tag.AWS_LAB
        is Slot.Lightnings -> Tag.LIGHTNING_TALK
        else ->
            when (room) {
                Room.VERWEY_KAMER -> Tag.NERD_RANCH
                Room.MENDES_DA_COSTA_KAMER -> Tag.AWS_LAB
                else -> Tag.NONE
            }

    }
}

fun tagImageSrc (tag: Tag): String {
    return when (tag) {
        Tag.NERD_RANCH -> "./aws_brackets.svg"
        Tag.AWS_LAB -> "./aws_brackets.svg"
        Tag.LIGHTNING_TALK -> "./lightning.svg"
        else -> ""
    }
}

fun dateToConfDay (date : Int): ConferenceDay {
    return when (date) {
        12 -> ConferenceDay.WORKSHOPS
        13 -> ConferenceDay.DAY_FIRST
        14 -> ConferenceDay.DAY_SECOND
        else -> ConferenceDay.DAY_SECOND
    }
}

fun confDayToRowsIndex (day : ConferenceDay): Int {
    return when (day) {
        ConferenceDay.WORKSHOPS -> 0
        ConferenceDay.DAY_FIRST -> 1
        ConferenceDay.DAY_SECOND -> 2
    }
}

fun currentScreen(day : ConferenceDay, moment : Moment, seconds : Int) : Screen {
    // 15 + 15 + 15 + 9 + 6
    return (
            if (seconds >= 0 && seconds < 15) Screen.HALLS_PART_ONE else
                if (seconds >= 15 && seconds < 30) Screen.HALLS_PART_TWO else
                    if (seconds >= 30 && seconds < 45) Screen.HALLS_PART_THREE else
                        if (seconds >= 45 && seconds < 54) Screen.SPONSORS else
                            Screen.APP_PROMO
    )
    // 20 + 20 + 12 + 8
    //return
    //    if (seconds >= 0 && seconds < 20) Screen.HALLS_PART_ONE else
    //    if (seconds >= 20 && seconds < 40) Screen.HALLS_PART_TWO else
    //    if (seconds >= 40 && seconds < 52) Screen.SPONSORS else
    //    SCREEN.APP_PROMO
}