import kotlinx.coroutines.async
import react.*
import react.dom.*
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import emotion.react.css
import csstype.*
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span

val iconHeight = 43.px
val iconMarginBottom = 10.px


val SpecialC = FC<SpecialCProps> { props ->
    val TickerC = TickerC::class.react

    div {
        className=ClassName("special")

        css {
            position = Position.relative
            display = Display.inlineBlock
            fontSize = 26.px
            color = KC_Color.WHITE_T60.v
            //height = rowHeight + (cellPaddingN * 2).px
        }

        when (props.special) {
            Special.BREAKFAST ->
                img {
                    css {
                        display = Display.block
                        height = iconHeight
                        marginBottom = iconMarginBottom
                    }

                    src = "/lunch.svg"
                }
            Special.LUNCH ->
                img {
                    css {
                        display = Display.block
                        height = iconHeight
                        marginBottom = iconMarginBottom
                    }

                    src = "/lunch.svg"
                }
            Special.COFFEE_BREAK ->
                img {
                    css {
                        display = Display.block
                        height = iconHeight
                        marginBottom = iconMarginBottom
                    }

                    src = "/coffee_break.svg"
                }
            Special.BREAK -> span {}
            Special.PARTY ->
                div {

                    css {
                        //position = Position.fixed
                        overflow = Overflow.hidden
                        height = rowHeight// + (cellPaddingN * 2).px
                    }

                    TickerC ({
                        chunk = "PARTY"
                        band = KC_Color.ORANGE.v
                        angle = 0.deg
                        top = 0.px
                        direction = 1
                    })

                    TickerC ({
                        chunk = "PARTY"
                        band = KC_Color.ORANGE.v
                        angle = 9.4.deg
                        //top = 1550.px
                        top = 10.vh
                        left = -250.px
                        //x = 100.pct
                        direction = 1 //-1
                    })

                    TickerC ({
                        chunk = "* HIT THE FLOOR *"
                        band = KC_Color.PURPLE.v
                        angle = (-8).deg
                        //top = -710.px
                        //top = -470.px
                        top = rowHeightNoPaddings - 150.px
                        left = -40.px
                        direction = 1
                    })

                }
            Special.END_OF_TALKS ->
                div {

                    css {
                        //position = Position.fixed
                        overflow = Overflow.hidden
                        height = rowHeight + (cellPaddingN * 2).px
                    }

                    TickerC ({
                        chunk = "[END OF TALKS]"
                        band = KC_Color.DARK.v
                        angle = 0.deg
                        top = 0.px
                        direction = 1
                    })

                }
        }

        if (props.special != Special.PARTY && props.special != Special.END_OF_TALKS)
            span { +"${ props.special.specialName }" }
        else
            span {}
    }
}

external interface SpecialCProps : Props {
    var special: Special
    var current : Boolean
}