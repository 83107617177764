import kotlinx.coroutines.async
import react.*
import react.dom.*
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import emotion.react.css
import csstype.*
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span

val LightningsC = FC<LightningsCProps> { props ->
    val twoTalks = props.pair
    val isFirstCurrent = fitsInterval(props.moment, twoTalks.first.first)
    val isSecondCurrent = fitsInterval(props.moment, twoTalks.second.first)
    val minSubRowHeight = props.pRowHeight / 2
    //val minSubRowWidth = 1800.px  // FIXME: // props.pColumnWidth + (cellPaddingN * 2).px
    val minSubRowWidth = props.pColumnWidth + (cellPaddingN * 2).px

        div {
            css {
                display = Display.flex
                flexDirection = FlexDirection.column
                //minHeight = props.minHeight
                //minWidth = 400.px
                //width = 100.pct
                //height = 100.pct
                minWidth = props.pColumnWidth // + (cellPaddingN * 2).px
                minHeight = props.pRowHeight// - (cellPaddingN * 2).px
            }

            div {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    borderBottomColor = KC_Color.WHITE.v
                    borderBottomWidth = 2.px
                    borderBottomStyle = LineStyle.solid
                    backgroundColor = if (isFirstCurrent) KC_Color.WHITE_T20.v else KC_Color.TRANSPARENT.v
                    minHeight = minSubRowHeight
                    minWidth = minSubRowWidth
                    overflow = Overflow.hidden
                    //width = 1800.px
                }
                div {
                    css {
                        padding = 26.px
                        backgroundColor = if (isFirstCurrent) KC_Color.PURPLE.v else KC_Color.TRANSPARENT.v
                    }
                    IntervalC { interval = twoTalks.first.first }
                }
                div {
                    css {
                        padding = 26.px
                        borderLeftColor = KC_Color.LIGHT_B.v
                        borderLeftWidth = 2.px
                        borderLeftStyle = LineStyle.solid
                    }
                    TalkC {
                        talk = twoTalks.first.second
                        tag = Tag.LIGHTNING_TALK
                    }
                }
            }

            div {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    backgroundColor = if (isSecondCurrent) KC_Color.WHITE_T20.v else KC_Color.TRANSPARENT.v
                    minHeight = minSubRowHeight - 2.px
                    minWidth = minSubRowWidth
                    overflow = Overflow.hidden
                }
                div {
                    css {
                        padding = 26.px
                        backgroundColor = if (isSecondCurrent) KC_Color.PURPLE.v else KC_Color.TRANSPARENT.v
                    }
                    IntervalC { interval = twoTalks.second.first }
                }
                div {
                    css {
                        padding = 26.px
                        borderLeftColor = KC_Color.LIGHT_B.v
                        borderLeftWidth = 2.px
                        borderLeftStyle = LineStyle.solid
                    }
                    TalkC {
                        talk = twoTalks.second.second
                        tag = Tag.LIGHTNING_TALK
                    }
                }
            }
        }
}

external interface LightningsCProps : Props {
    var pair : TwoTalks
    var moment : Moment
    var pRowHeight : Length
    var pColumnWidth : Length
}