import react.*
import react.dom.*
import react.dom.html.ReactHTML.span
import emotion.react.css
import csstype.*

val IntervalC = FC<IntervalCProps> { props ->
    span {
        css {
            fontSize = 40.px
            lineHeight = 36.px
            color = KC_Color.WHITE.v
            fontWeight = FontWeight.bolder
            minWidth = timeCellWidth
        }
        +"${formatMoment(props.interval.first)}"
    }
}

external interface IntervalCProps : Props {
    var interval : Interval
}