import kotlinx.browser.window
import react.*
import react.dom.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.button
import emotion.react.css
import csstype.*
import kotlinx.browser.*
import kotlin.js.Date


val topPanelOnAtStart = false // move to props?


class MomentProviderCState (
  var hours : Int,
  var minutes : Int,
  var seconds : Int,
  var date : Int,
  var month : Int,
  var forced : Boolean,
  var forcedScreen : Screen?,
  var topPanelOn : Boolean
) : State


external interface MomentProviderCProps : Props {

}

class MomentProviderC(): Component<MomentProviderCProps, MomentProviderCState>() {

  val curTime = js("new Date()")
  init {
    state =
        MomentProviderCState(
            curTime.getHours(),
            curTime.getMinutes(),
            curTime.getSeconds(),
            curTime.getDate(),
            curTime.getMonth(),
            false,
            null,
            topPanelOnAtStart
        )
  }

  var rafId: Int? = null

  override fun componentDidMount() {
    fun tickRAF(param: Double) {
      tick(param)
      rafId = window.requestAnimationFrame(::tickRAF)
    }

    rafId = window.requestAnimationFrame(::tickRAF)
  }

  fun tick(param: Double) {
    val curTime = js("new Date()")
    if (!state.forced) {
          setState ( MomentProviderCState(
                  hours = curTime.getHours(),
                  minutes = curTime.getMinutes(),
                  seconds = curTime.getSeconds(),
                  date = curTime.getDate(),
                  month = curTime.getMonth(),
                  forced = false,
                  forcedScreen = state.forcedScreen,
                  topPanelOn = state.topPanelOn
          ) )
    }
  }

  override fun componentWillUnmount() {
    window.cancelAnimationFrame(rafId!!)
  }

  //override fun RBuilder.render() {
   override fun render() : ReactNode {
        val conferenceDay = dateToConfDay(state.date)
        //val conferenceDay = ConferenceDay.SECOND
        val rowsIndex = confDayToRowsIndex(conferenceDay)
        val currentMoment = Moment(state.hours, state.minutes)
        //val currentMoment = Moment(17, 20)
        val screenToShow = state.forcedScreen ?: currentScreen(conferenceDay, currentMoment, state.seconds)
        //val screenToShow = Screen.HALLS_PART_TWO
        val rowsToMoment = schedule.get(rowsIndex).rows.filterKeys { duringOrAfterInterval(currentMoment, it) }

        val roomsToMoment =
                when (screenToShow) {
                    //rooms = arrayOf(Room.EFFECTENBEURSZAAL, Room.GRAANBEURSZAAL, Room.ADMINISTRATIEZAAL, Room.BERLAGE_ZAAL)
                    //rooms = arrayOf(Room.EFFECTENBEURSZAAL, Room.GRAANBEURSZAAL, Room.ADMINISTRATIEZAAL)
                    //rooms = arrayOf(Room.BERLAGE_ZAAL, Room.VEILINGZAAL)
                    //rooms = arrayOf(Room.GRAANBEURSZAAL, Room.VERWEY_KAMER, Room.MENDES_DA_COSTA_KAMER)
                    //rooms = arrayOf(Room.EFFECTENBEURSZAAL, Room.GRAANBEURSZAAL, Room.ADMINISTRATIEZAAL, Room.VERWEY_KAMER, Room.MENDES_DA_COSTA_KAMER)
                    Screen.HALLS_PART_ONE ->
                        if (conferenceDay == ConferenceDay.WORKSHOPS) {
                            arrayOf(Room.ADMINISTRATIEZAAL, Room.VEILINGZAAL, Room.VERWEY_KAMER)
                        } else {
                            arrayOf(Room.EFFECTENBEURSZAAL, Room.GRAANBEURSZAAL, Room.ADMINISTRATIEZAAL)
                        }
                    Screen.HALLS_PART_TWO ->
                        if (conferenceDay == ConferenceDay.WORKSHOPS) {
                            arrayOf(Room.MENDES_DA_COSTA_KAMER, Room.BLAUWE_KAMER, Room.DERKINDEREN_KAMER)
                        } else {
                            arrayOf(Room.BERLAGE_ZAAL, Room.VEILINGZAAL)
                        }
                    Screen.HALLS_PART_THREE ->
                        if (conferenceDay == ConferenceDay.WORKSHOPS) {
                            arrayOf(Room.ROLAND_HOST_KAMER, Room.ONTVANGKAMER)
                        } else {
                            arrayOf(Room.VERWEY_KAMER, Room.MENDES_DA_COSTA_KAMER)
                        }
                    Screen.SPONSORS -> arrayOf()
                    Screen.APP_PROMO -> arrayOf()
                }
        return div.create {

            div {

                css {
                  backgroundColor = KC_Color.BLACK.v
                  width = appWidth
                  height = appHeight
                }

                when (screenToShow) {
                    Screen.HALLS_PART_ONE, Screen.HALLS_PART_TWO, Screen.HALLS_PART_THREE ->
                        ScheduleScreenC ({
                            rows = rowsToMoment
                            confDay = conferenceDay
                            rooms = roomsToMoment
                            moment = currentMoment
                        })
                    Screen.SPONSORS ->
                        SponsorsC {}
                    Screen.APP_PROMO ->
                        PromoC {}
                }

                if (state.topPanelOn) {
                    div {
                        //className = ClassName("manual-setup")

                        css {
                            position = Position.fixed
                            color = KC_Color.WHITE.v
                            //zIndex = ZIndex
                            top = 0.px
                            right = 0.px
                        }

                        button {
                            css { fontWeight = if (screenToShow == Screen.HALLS_PART_ONE) FontWeight.bolder else FontWeight.normal }
                            onClick = { _ -> forceScreen(Screen.HALLS_PART_ONE) }
                            +"H1"
                        }

                        button {
                            css { fontWeight = if (screenToShow == Screen.HALLS_PART_TWO) FontWeight.bolder else FontWeight.normal }
                            onClick = { _ -> forceScreen(Screen.HALLS_PART_TWO) }
                            +"H2"
                        }

                        button {
                            css { fontWeight = if (screenToShow == Screen.HALLS_PART_THREE) FontWeight.bolder else FontWeight.normal }
                            onClick = { _ -> forceScreen(Screen.HALLS_PART_THREE) }
                            +"H3"
                        }

                        button {
                            css { fontWeight = if (screenToShow == Screen.SPONSORS) FontWeight.bolder else FontWeight.normal }
                            onClick = { _ -> forceScreen(Screen.SPONSORS) }
                            +"SP"
                        }

                        button {
                            css { fontWeight = if (screenToShow == Screen.APP_PROMO) FontWeight.bolder else FontWeight.normal }
                            onClick = { _ -> forceScreen(Screen.APP_PROMO) }
                            +"PR"
                        }

                        when (screenToShow) {

                            Screen.HALLS_PART_ONE, Screen.HALLS_PART_TWO, Screen.HALLS_PART_THREE -> {

                                button {
                                    css { fontWeight = if (conferenceDay == ConferenceDay.WORKSHOPS) FontWeight.bolder else FontWeight.normal }
                                    onClick = { _ -> forceConferenceDay(ConferenceDay.WORKSHOPS) }
                                    +"WSD"
                                }

                                button {
                                    css { fontWeight = if (conferenceDay == ConferenceDay.DAY_FIRST) FontWeight.bolder else FontWeight.normal }
                                    onClick = { _ -> forceConferenceDay(ConferenceDay.DAY_FIRST) }
                                    +"1ST"
                                }

                                button {
                                    css { fontWeight = if (conferenceDay == ConferenceDay.DAY_SECOND) FontWeight.bolder else FontWeight.normal }
                                    onClick = { _ -> forceConferenceDay(ConferenceDay.DAY_SECOND) }
                                    +"2ND"
                                }

                                button {
                                    onClick = { _ -> forceMoment(Moment(10, 12)) }
                                    +"10:12"
                                }

                                button {
                                    onClick = { _ -> forceMoment(Moment(12, 34)) }
                                    +"12:34"
                                }

                                button {
                                    onClick = { _ -> forceMoment(Moment(13, 14)) }
                                    +"13:14"
                                }

                                button {
                                    onClick = { _ -> forceMoment(Moment(13, 31)) }
                                    +"13:31"
                                }

                                button {
                                    onClick = { _ -> forceMoment(Moment(15, 40)) }
                                    +"15:40"
                                }

                                button {
                                    onClick = { _ -> forceMoment(Moment(16, 20)) }
                                    +"16:20"
                                }

                                button {
                                    onClick = { _ -> forceMoment(Moment(17, 9)) }
                                    +"17:09"
                                }

                                button {
                                    onClick = { _ -> forceMoment(Moment(18, 12)) }
                                    +"18:12"
                                }

                            }

                            else ->

                                span {}

                        }

                        button {
                            css { fontWeight = if (state.forced) FontWeight.bolder else FontWeight.normal }
                            onClick = { _ -> resetEverythingForced() }
                            +"R"
                        }

                        button {
                            css { fontWeight = if (state.topPanelOn) FontWeight.bolder else FontWeight.normal }
                            onClick = { _ -> disableTopPanel() }
                            +"X"
                        }
                    }
                }

            }

        }
  }

    fun disableTopPanel() {
        setState( MomentProviderCState(
            date = state.date,
            month = state.month,
            hours = state.hours,
            minutes = state.minutes,
            seconds = state.seconds,
            forced = false,
            forcedScreen = null,
            topPanelOn = false
     ))
    }

  fun resetEverythingForced() {
      val curTime = js("new Date()")
      setState ( MomentProviderCState(
              hours = curTime.getHours(),
              minutes = curTime.getMinutes(),
              seconds = curTime.getSeconds(),
              date = curTime.getDate(),
              month = curTime.getMonth(),
              forced = false,
              forcedScreen = null,
              topPanelOn = state.topPanelOn
      ) )
  }

  fun forceConferenceDay(cday : ConferenceDay) {
      setState( MomentProviderCState(
            date = cday.date,
            month = 4,
            hours = state.hours,
            minutes = state.minutes,
            seconds = state.seconds,
            forced = true,
            forcedScreen = state.forcedScreen,
            topPanelOn = state.topPanelOn
     ))
  }

  fun forceScreen(screen : Screen) {
      setState( MomentProviderCState(
            date = state.date,
            month = state.month,
            hours = state.hours,
            minutes = state.minutes,
            seconds = state.seconds,
            forced = true,
            forcedScreen = screen,
            topPanelOn = state.topPanelOn
     ))
  }

  fun forceMoment(moment : Moment) {
      setState( MomentProviderCState(
            date = state.date,
            month = state.month,
            hours = moment.hour,
            minutes = moment.minute,
            seconds = state.seconds,
            forced = true,
            forcedScreen = state.forcedScreen,
            topPanelOn = state.topPanelOn
     ))
  }

}
