import kotlinx.coroutines.async
import react.*
import react.dom.*
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import emotion.react.css
import csstype.*
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span

//@JsModule("src/main/resources/adyen.svg")
//@JsNonModule
//external val logo: dynamic

//@JsModule("./adyen.svg")
//@JsNonModule
//external val logo: dynamic

val TagC = FC<TagProps> { props ->
    val tag = props.tag // ?: Tag.NONE
    val tagName = tag.tagName ?: ""

    span {
        className = ClassName("tag-content")

        when (tag) {
            Tag.NONE ->
                span {}
            Tag.LIGHTNING_TALK ->
                span {}
            else ->
                span {

                    img {
                        css {
                            position = Position.relative
                            marginRight = 15.px
                            marginLeft = 2.px
                            top = 1.px
                            height = 20.px
                        }

                        src = tagImageSrc(tag)
                    }

                    css {
                        color = KC_Color.WHITE_T60.v
                        borderColor = KC_Color.LIGHT_B.v
                        borderWidth = 1.px
                        borderStyle = LineStyle.solid
                        fontSize = 26.px
                        padding = array(5.px, 12.px)
                    }
                    /*when (tag) {
                        Tag.LIGHTNING_TALK -> +"${tagName} ${formatInterval(Moment(10,0) to Moment(10,15))}"
                        else -> +tagName
                    } */
                    +tagName
                }
        }
    }
}

external interface TagProps : Props {
    var tag : Tag
}