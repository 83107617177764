import kotlinx.coroutines.async
//import kotlinx.css
import react.*
import react.dom.*
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import emotion.react.css
import csstype.*
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span


val cellPaddingN = 28
val cellPadding = cellPaddingN.px
val clockPadding = cellPadding// array(28.px, 28.px, 28.px, 28.px)
val headerCellPadding = cellPadding//array(28.px, 28.px, 28.px, 28.px)

val timeCellWidth = 175.px - (cellPaddingN * 2).px
val allRoomsColumnsWidth = 100.pct - timeCellWidth
val calcColumnWidth = { rooms: Int -> (allRoomsColumnsWidth / rooms) }

val headerHeight = 86.px
val rowHeight = (appHeight - headerHeight) / 3 // ((100.vh - headerHeightMinLimit) / 3)
val rowHeightNoPaddings = rowHeight - (cellPaddingN * 2).px
val specialHeight = rowHeightNoPaddings

val clockTop = -2.px
val intervalTop = 0.px

val tagsInHeaders = false


external interface ScheduleScreenCProps : Props {
    var confDay: ConferenceDay
    var rows: Map<Interval, Row>
    var rooms: Array<Room>
    var moment: Moment
}

val ScheduleScreenC = FC<ScheduleScreenCProps> { props ->

    div {
        css {
            display = Display.flex
            width = appWidth
            flexDirection = FlexDirection.column
            lineHeight = 32.px
            backgroundColor = KC_Color.BLACK.v
        }

        div {
            css {
                position = Position.absolute
                top = 0.px
                left = 0.px
                color = Color("#FFFFFF")
            }
        }

        div {

            css {
                display = Display.flex
                borderBottomColor = KC_Color.WHITE.v
                borderBottomWidth = 2.px
                borderBottomStyle = LineStyle.solid
                height = headerHeight
            }

            div {
                className=ClassName("clock")

                css {
                    display = Display.inlineBlock
                    width = timeCellWidth
                    padding = clockPadding
                    textAlign = TextAlign.center
                    top = clockTop
                    position = Position.relative
                }

                ClockC { moment = props.moment }
            }

            ScheduleHeaderC { rooms = props.rooms }
        }


        for (rowEntry in props.rows.entries.iterator()) {
            val rowInterval = rowEntry.key
            val currentRow = rowEntry.value
            val isCurrent = fitsInterval(props.moment, rowInterval)
            div {

                css {
                    display = Display.flex
                    borderBottomColor = KC_Color.WHITE.v
                    borderBottomWidth = 2.px
                    borderBottomStyle = LineStyle.solid
                    backgroundColor = if (isCurrent) KC_Color.WHITE_T20.v else KC_Color.TRANSPARENT.v
                    height = rowHeight
                }

                div {
                    className=ClassName("interval")

                    css {
                        display = Display.inlineBlock
                        width = timeCellWidth
                        padding = clockPadding
                        textAlign = TextAlign.center
                        top = intervalTop
                        position = Position.relative
                        height = rowHeightNoPaddings
                        backgroundColor =
                            when (currentRow) {
                                is Row.IsSpecial -> if (isCurrent) (if (currentRow.which != Special.END_OF_TALKS) KC_Color.PURPLE.v else KC_Color.WHITE_T20.v) else KC_Color.TRANSPARENT.v
                                is Row.Slots -> if (isCurrent) KC_Color.PURPLE.v else KC_Color.TRANSPARENT.v
                            }
                    }

                    IntervalC({ interval = rowInterval })
                }

                ScheduleRowC({
                    row = currentRow
                    rooms = props.rooms
                    moment = props.moment
                })
            }

        }
    }
}

external interface ScheduleRowCProps : Props {
    var row: Row
    var rooms: Array<Room>
    var moment : Moment
}

val ScheduleRowC = FC<ScheduleRowCProps> { props ->
    val row = props.row
    val columnWidth = calcColumnWidth(props.rooms.size)
    val columnWidthNoPaddings = columnWidth - (cellPaddingN * 2).px
    when (row) {
        is Row.IsSpecial ->
            div {
                val hasPadding = row.which != Special.PARTY && row.which != Special.END_OF_TALKS
                css {
                    padding = if (hasPadding) cellPadding else 0.px
                    display = Display.inlineBlock
                    borderLeftColor = KC_Color.LIGHT_B.v
                    borderLeftWidth = 2.px
                    borderLeftStyle = LineStyle.solid
                    height = if (hasPadding) specialHeight else specialHeight + (2 * cellPaddingN).px
                    width = allRoomsColumnsWidth
                }
                SpecialC { special = row.which }
            }
        is Row.Slots ->
            div {

                css {
                    display = Display.inlineFlex
                    flexDirection = FlexDirection.row
                    width = allRoomsColumnsWidth
                    justifyContent = JustifyContent.flexStart
                    color = KC_Color.GRAY_5.v
                }

                for (room in props.rooms) {
                    val slot = row.slots.get(room) ?: Slot.Empty()
                    val current = false
                    val talkTag = tagFor(room, slot)

                    div {
                        css {
                            display = Display.inlineBlock
                            position = Position.relative
                            width = when (slot) {
                                    is Slot.Lightnings -> columnWidth + 113.px // FIXME: some weird CSS trick
                                    else -> columnWidthNoPaddings
                                }
                            padding =
                                when (slot) {
                                    is Slot.Lightnings -> 0.px
                                    else -> cellPadding
                                }
                            minHeight = when (slot) {
                                    is Slot.Lightnings -> rowHeight
                                    else -> rowHeightNoPaddings
                                }
                            borderLeftColor = KC_Color.LIGHT_B.v
                            borderLeftWidth = 2.px
                            borderLeftStyle = LineStyle.solid
                        }
                        when (slot) {
                            //is Slot.Usual, is Slot.Workshop, is Slot.NerdRanch, is Slot.AwsLab ->
                            is Slot.Usual ->
                                TalkC {
                                    talk = slot.talk
                                    tag = talkTag
                                }
                            is Slot.Workshop ->
                                TalkC {
                                    talk = slot.talk
                                    tag = talkTag
                                }
                            is Slot.NerdRanch ->
                                TalkC {
                                    talk = slot.talk
                                    tag = talkTag
                                }
                            is Slot.AwsLab ->
                                TalkC {
                                    talk = slot.talk
                                    tag = talkTag
                                }
                            is Slot.Lightnings ->
                                LightningsC {
                                    pair = slot.pair
                                    moment = props.moment
                                    pRowHeight = rowHeight
                                    pColumnWidth = columnWidth
                                }
                            else ->
                                div {
                                    css { minWidth = columnWidthNoPaddings }
                                    +""
                                }
                        }
                    }

                }

            }
    }
}

external interface ScheduleHeaderCProps : Props {
    var rooms: Array<Room>
}

val ScheduleHeaderC = FC<ScheduleHeaderCProps> { props ->

    val columnWidth = calcColumnWidth(props.rooms.size)
    val columnWidthNoPaddings = columnWidth - (cellPaddingN * 2).px

    div {
        css {
            display = Display.inlineFlex
            flexDirection = FlexDirection.row
            width = allRoomsColumnsWidth
            justifyContent = JustifyContent.flexStart
            color = KC_Color.WHITE.v
            fontWeight = FontWeight.bolder
            fontSize = 26.px
            height = headerHeight
        }

        for (room in props.rooms) {
            div {
                css {
                    display = Display.inlineBlock
                    width = columnWidthNoPaddings
                    padding = headerCellPadding
                    borderLeftColor = KC_Color.LIGHT_B.v
                    borderLeftWidth = 2.px
                    borderLeftStyle = LineStyle.solid
                }

                when (room) {
                    Room.VERWEY_KAMER ->
                        (span {
                            span {
                                css { marginRight = 15.px }
                                +"${ room.roomName }"
                            }
                            if (tagsInHeaders) TagC { tag = Tag.NERD_RANCH } else span {}
                        })
                    Room.MENDES_DA_COSTA_KAMER ->
                        (span {
                            span {
                                css { marginRight = 15.px }
                                +"${ room.roomName }"
                            }
                            if (tagsInHeaders) TagC { tag = Tag.AWS_LAB } else span {}
                        })
                    else -> span { +"${ room.roomName }" }
                }

            }
        }
    }

}



