import kotlinx.coroutines.async
import react.*
import react.dom.*
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import emotion.react.css
import csstype.*
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span

val appHeight = max(1080.px, min(100.vh, 1080.px))
val appWidth = max(1600.px, min(100.pct, 1920.px))

val App = FC<Props> {
    div {
        css {
            //backgroundColor = KC_Color.DARK.v
            //backgroundColor = KC_Color.BLACK.v
            backgroundColor = KC_Color.WHITE.v
            overflow = Overflow.hidden
            height = appHeight
        }

        val MomentProviderC = MomentProviderC::class.react

        MomentProviderC()
    }
}