import kotlinx.browser.window
import react.*
import react.dom.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import emotion.react.css
import csstype.*
import kotlinx.browser.*
//import kotlinx.css.*
import kotlin.js.Date


val SPEED = 0.07
val SPAN = 40.0 // seconds to restart from scratch

val tickerHeight = 54.px
val tickerFontSize = 27.px

class TickerCState (
  var dt : Double,
  var passed : Double,
  var spanned : Double
) : State


external interface TickerCProps : Props {
    var chunk : String
    var band : Color
    var angle : Angle
    var top : Length
    var left : Length
    var direction : Int
}

class TickerC(props: TickerCProps): Component<TickerCProps, TickerCState>(props) {

  init {
    state = TickerCState(0.0, 0.0, 0.0)
  }

  var rafId: Int? = null

  override fun componentDidMount() {
    fun tickRAF(param: Double) {
      tick(param)
      rafId = window.requestAnimationFrame(::tickRAF)
    }

    rafId = window.requestAnimationFrame(::tickRAF)
  }

  fun tick(param: Double) {
      setState ( TickerCState(
          passed = param,
          dt = param - state.passed,
          spanned = (param % (SPAN * 1000.0)) * SPEED
          //spanned = param * SPEED
      ) )
  }

  override fun componentWillUnmount() {
    window.cancelAnimationFrame(rafId!!)
  }

  //override fun RBuilder.render() {
   override fun render() : ReactNode {
        val manyChunks : String = (" " + props.chunk + " ").repeat(200)
        return div.create {

            /* css {
                position = Position.relative
                left = -100.pct // state.spanned.px
                overflow = Overflow.hidden
                width = 200.pct
                maxWidth = 200.pct
                backgroundColor = props.band
                maxHeight = 1.2.em
                transform = rotate(props.angle)
            } */

            css {
                position = Position.relative
                backgroundColor = props.band
                maxHeight = tickerHeight
                width = 400.pct
                overflow = Overflow.hidden
                transformOrigin = TransformOrigin(offsetX = 0.pct, offsetY = 50.pct)
                transform = rotate(props.angle)
                // transform = translate(20.px)
                //whiteSpace = WhiteSpace("nowrap")
                top = props.top
                left = props.left
                lineHeight = tickerHeight
                fontSize = tickerFontSize
                fontWeight = FontWeight.bolder
                color = KC_Color.WHITE.v
            }

            span {
                /* css {
                    display = Display.inline
                    position = Position.relative
                    left = state.spanned.px
                } */

                css {
                    position = Position.relative
                    //transform = translate(state.spanned.px, 0.0)
                    left = (props.direction * -50).pct + (props.direction * state.spanned).px
                    //left = (-100).pct + 100.px
                    //bottom = 0
                }

                +manyChunks
            }
        }
  }

}
