import kotlinx.coroutines.async
import react.*
import react.dom.*
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import emotion.react.css
import csstype.*
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span

//@JsModule("src/main/resources/adyen.svg")
//@JsNonModule
//external val logo: dynamic

val TalkC = FC<TalkCProps> { props ->
    //val tag = props.tag ?: Tag.NONE
    //val tagName = tag.tagName?.let { it -> "(${ it })" } ?: ""
    div {
        css {
            display = Display.flex
            flexDirection = FlexDirection.column
        }
        span {
            css {
                display = Display.block
                color = KC_Color.GRAY_5.v
                fontWeight = FontWeight.bold
                fontSize = 26.px
                paddingBottom = 12.px
            }
            +"${ props.talk.title }"
        }
        span {
            css {
                color = KC_Color.WHITE_T60.v
                fontWeight = FontWeight.normal
                fontSize = 26.px
                letterSpacing = 0.0015.em
            }
            for (speaker in props.talk.speakers) {
                span {
                    css {
                        display = Display.block
                    }
                    +"${ speaker.name } ${ speaker.surname }"
                }
            }

        }
    }

    span {
        css {
            display = Display.inlineBlock
            position = Position.absolute
            bottom = 20.px
        }

        TagC { tag = props.tag ?: Tag.NONE }
    }
}

external interface TalkCProps : Props {
    var talk : Talk
    var tag : Tag
}