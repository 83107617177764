
//import kotlinx.DateTime.Clock
//import kotlin.time.*
//import kotlin.js.JsObj
//import kotlin.browser.window
import kotlin.js.Date

import react.*
import react.dom.*
import react.dom.html.ReactHTML.span
import emotion.react.css
import csstype.*


val ClockC = FC<ClockCProps> { props ->
    val date = Date.now()
    span {
        css {
            fontSize = 40.px
            lineHeight = 36.px
            color = KC_Color.WHITE.v
            fontWeight = FontWeight.bolder
        }
        +"${formatMoment(props.moment)}"
    }
}

external interface ClockCProps : Props {
    var moment : Moment
}