import kotlinx.browser.document
import react.*
import emotion.react.css
import csstype.Position
import csstype.px
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.img
import react.dom.client.createRoot
import kotlinx.serialization.Serializable


enum class Room(val roomName : String) {
    EFFECTENBEURSZAAL("EFFECTENBEURSZAAL"),
    GRAANBEURSZAAL("GRAANBEURSZAAL"),
    ADMINISTRATIEZAAL("ADMINISTRATIEZAAL"),
    MENDES_DA_COSTA_KAMER("MENDES DA COSTA KAMER"),
    BERLAGE_ZAAL("BERLAGE ZAAL"),
    VEILINGZAAL("VEILINGZAAL"),
    VERWEY_KAMER("VERWEY KAMER"),
    BLAUWE_KAMER("BLAUWE KAMER"),
    DERKINDEREN_KAMER("DERKINDEREN KAMER"),
    ROLAND_HOST_KAMER("ROLAND HOST KAMER"),
    ONTVANGKAMER("ONTVANGKAMER")
}

enum class Special(val specialName: String) {
    BREAKFAST("Breakfast"),
    BREAK("Break"),
    LUNCH("Lunch"),
    PARTY("Party"),
    COFFEE_BREAK("Coffee Break"),
    END_OF_TALKS("End of Talks")
}

enum class Tag(val tagName: String?) {
    NERD_RANCH("Big Nerd Ranch Lab"),
    AWS_LAB("AWS Lab"),
    LIGHTNING_TALK("Lightning Talk"),
    NONE(null)
}

data class Moment (
    val hour : Int,
    val minute : Int
)

data class Date (
    val day : Int,
    val month : Int
)

enum class ConferenceDay(val date: Int) {
    WORKSHOPS(12),
    DAY_FIRST(13),
    DAY_SECOND(14)
}

typealias Interval = Pair<Moment,Moment>

typealias TwoTalks = Pair< Pair<Interval,Talk>, Pair<Interval,Talk> >

sealed class Slot {
    class Usual(val talk: Talk) : Slot()
    class Workshop(val slot : Int, val talk: Talk) : Slot()
    class Lightnings(val pair : TwoTalks) : Slot()
    class NerdRanch(val talk: Talk) : Slot()
    class AwsLab(val talk: Talk) : Slot()
    class Empty() : Slot()
}

sealed class Row {
    class IsSpecial(val which: Special) : Row()
    class Slots(val slots : Map<Room, Slot>) : Row()
}

data class Speaker (
    val name: String,
    val surname: String
)

data class Talk (
    val title: String,
    val speakers: List<Speaker>
)

data class Day (
    val date : Date,
    val rows : Map<Interval, Row>
)

typealias Schedule = List<Day>

/* DAY 0. WORKSHOPS */

fun workshops (slot : Int) =
    mapOf(
        Room.ADMINISTRATIEZAAL to Slot.Workshop(slot, Talk("Building Scalable Microservices With Ktor and Kafka", listOf(Speaker("Anton", "Arhipov"), Speaker("Viktor", "Gamov")))),
        Room.VEILINGZAAL to Slot.Workshop(slot, Talk("Asynchronous Programming With Kotlin Coroutines", listOf(Speaker("Svetlana", "Isakova"), Speaker("Sebastian", "Aigner")))),
        Room.VERWEY_KAMER to Slot.Workshop(slot, Talk("Functional Programming in Kotlin", listOf(Speaker("Simon", "Vergauwen"), Speaker("Alejandro Serrano", "Mena")))),
        Room.MENDES_DA_COSTA_KAMER to Slot.Workshop(slot, Talk("Reactive Spring Boot With Coroutines", listOf(Speaker("Urs", "Peter")))),
        Room.BLAUWE_KAMER to Slot.Workshop(slot, Talk("Kotlin Multiplatform Mobile in Production", listOf(Speaker("Kevin", "Galligan")))),
        Room.DERKINDEREN_KAMER to Slot.Workshop(slot, Talk("Multiplatform Madness With Compose", listOf(Speaker("Garth", "Gilmour")))),
        Room.ROLAND_HOST_KAMER to Slot.Workshop(slot, Talk("Elegant, Powerful, and Idiomatic APIs in Kotlin", listOf(Speaker("Salomon", "Brys")))),
        Room.ONTVANGKAMER to Slot.Workshop(slot, Talk("Kotlin TDD Masterclass", listOf(Speaker("Nat", "Pryce"), Speaker("Duncan", "McGregor"))))
    )

/* DAY 1 */

val day2_keynote =
    mapOf(
        Room.EFFECTENBEURSZAAL to Slot.Usual(Talk("Opening Keynote", listOf(Speaker("Roman", "Elizarov"), Speaker("Grace", "Kloba"), Speaker("Svetlana", "Isakova"), Speaker("Egor", "Tolstoy"))))
    )

val day2_first_row =
    mapOf(
        Room.EFFECTENBEURSZAAL to Slot.Usual(Talk("Adopting Kotlin at Google scale", listOf(Speaker("Jeffrey", "van Gogh"), Speaker("John", "Pampuch")))),
        Room.GRAANBEURSZAAL to Slot.Usual(Talk("Kotlin & Functional Programming: pick the best, skip the rest", listOf(Speaker("Urs", "Peter")))),
        Room.ADMINISTRATIEZAAL to Slot.Usual(Talk("Exploring the Testing Hyperpyramid with Kotlin and http4k", listOf(Speaker("David", "Denton"), Speaker("Ivan", "Sanchez")))),
        Room.BERLAGE_ZAAL to Slot.Usual(Talk("Coroutines Beyond Concurrency", listOf(Speaker("Alex", "Semin")))),
        Room.VEILINGZAAL to Slot.Lightnings(
            ((Moment(10, 15) to Moment(10, 30)) to Talk("Elevated Gardening with the Kotlin Ecosystem", listOf(Speaker("Brian", "Norman"))))
            to ((Moment(10, 40) to Moment(10, 55)) to Talk("Scale McDonald's with KMM", listOf(Speaker("Jackson", "Mafra"), Speaker("Cas", "van Luijtelaar"))))
        )
        // inferred type is Pair<Pair<Pair<Moment, Moment>, Talk>, Pair<Pair<Moment, Moment>, Talk>> but Pair<Pair<Moment, Talk>, Pair<Moment, Talk>>
    )

val day2_second_row =
    mapOf(
        Room.EFFECTENBEURSZAAL to Slot.Usual(Talk("Spring + Kotlin = Modern + Reactive + Productive", listOf(Speaker("Josh", "Long"), Speaker("James", "Ward")))),
        Room.GRAANBEURSZAAL to Slot.Usual(Talk("Dissecting Kotlin: Unsealing the Sealed, the SAM, and Other Syntax", listOf(Speaker("Huyen", "Tue Dao")))),
        Room.ADMINISTRATIEZAAL to Slot.Usual(Talk("Arrow 2.0's Trajectory", listOf(Speaker("Simon", "Vergauwen")))),
        Room.BERLAGE_ZAAL to Slot.Usual(Talk("Crash Course on the Kotlin Compiler", listOf(Speaker("Amanda", "Hinchman-Dominguez")))),
        Room.VEILINGZAAL to Slot.Lightnings(
            ((Moment(11, 15) to Moment(11, 30)) to Talk("Kotlin Multiplatform in Google Workspace", listOf(Speaker("Jason", "Parachoniak"))))
            to ((Moment(11, 40) to Moment(11, 55)) to Talk("5 years of Multiplatform Settings", listOf(Speaker("Russell", "Wolf"))))
        ),
        Room.VERWEY_KAMER to Slot.NerdRanch(Talk("Compose for Desktop", listOf(Speaker("Bryan", "Sills")))),
        Room.MENDES_DA_COSTA_KAMER to Slot.AwsLab(Talk("AWS Amplify and Project Setup", listOf(Speaker("Muhammed", "Salih Guler"), Speaker("Tyler", "Roach"), Speaker("Anshul", "Gupta"))))
    )

val day2_third_row =
    mapOf(
        Room.EFFECTENBEURSZAAL to Slot.Usual(Talk("Kotlin Multiplatform Conversions at Android Jetpack Scale", listOf(Speaker("Paul", "Malolepsy"), Speaker("Dustin", "Lam")))),
        Room.GRAANBEURSZAAL to Slot.Usual(Talk("Meta-programming with KSP and Kotlin compiler plugins", listOf(Speaker("Tadeas", "Kriz")))),
        Room.ADMINISTRATIEZAAL to Slot.Usual(Talk("Replacing SQL with Kotlin's 'dataframe' on the Las Vegas Strip", listOf(Speaker("Andrew", "Goldberg")))),
        Room.BERLAGE_ZAAL to Slot.Usual(Talk("The Layout Animations Show", listOf(Speaker("Nicole", "Terc")))),
        Room.VEILINGZAAL to Slot.Lightnings(
            ((Moment(13,  0) to Moment(13, 15)) to Talk("The Art of KMP: how I learned backend development with Kotlin and why you...", listOf(Speaker("Lena", "Stepanova"))))
            to ((Moment(13, 25) to Moment(13, 40)) to Talk("Put your Ktor Microservices on Kubernetes without a JVM!", listOf(Speaker("Bjorn", "van der Laan"))))
        ),
    )

val day2_fourth_row =
    mapOf(
        Room.EFFECTENBEURSZAAL to Slot.Usual(Talk("Confetti: building a Kotlin Multiplatform conference app in 40min", listOf(Speaker("John", "O'Reilly"), Speaker("Martin", "Bonnin")))),
        Room.GRAANBEURSZAAL to Slot.Usual(Talk("Untangling Coroutine Testing", listOf(Speaker("Márton", "Braun")))),
        Room.ADMINISTRATIEZAAL to Slot.Usual(Talk("7±2 things you didn't know about Exposed", listOf(Speaker("Alexey", "Soshin")))),
        Room.BERLAGE_ZAAL to Slot.Usual(Talk("Showkase: Automated UI Infrastructure in Jetpack Compose using KSP", listOf(Speaker("Vinay", "Gaba")))),
        Room.VEILINGZAAL to Slot.Lightnings(
            ((Moment(14,  0) to Moment(14, 15)) to Talk("Interstellar social simulation framework and game development in Kotlin", listOf(Speaker("Kwun", "Hang Lai"))))
            to ((Moment(14, 25) to Moment(14, 40)) to Talk("Nicer data transformation with KopyKat and Optics", listOf(Speaker("Alejandro Serrano", "Mena"))))
        ),
        Room.MENDES_DA_COSTA_KAMER to Slot.AwsLab(Talk("Adding Authentication", listOf(Speaker("Anshul", "Gupta"), Speaker("Tyler", "Roach"), Speaker("Muhammed", "Salih Guler"))))
    )


val day2_fifth_row =
    mapOf(
        Room.EFFECTENBEURSZAAL to Slot.Usual(Talk("Compose Multiplatform on iOS", listOf(Speaker("Sebastian", "Aigner"), Speaker("Nikita", "Lipsky")))),
        Room.GRAANBEURSZAAL to Slot.Usual(Talk("Practical Tips For Legacy Java Codebases Conversion Into Kotlin", listOf(Speaker("Younes", "Charfaoui")))),
        Room.ADMINISTRATIEZAAL to Slot.Usual(Talk("Writing backend APIs in a functional programming style", listOf(Speaker("James", "Lamine")))),
        Room.BERLAGE_ZAAL to Slot.Usual(Talk("How we test concurrent algorithms in Kotlin Coroutines", listOf(Speaker("Nikita", "Koval")))),
        Room.VERWEY_KAMER to Slot.NerdRanch(Talk("Introduction to Context Receivers", listOf(Speaker("Bryan", "Sills")))),
    )


val day2_sixth_row =
    mapOf(
        Room.EFFECTENBEURSZAAL to Slot.Usual(Talk("Reflections on a Year of Compose", listOf(Speaker("Christina", "Lee")))),
        Room.GRAANBEURSZAAL to Slot.Usual(Talk("Scale to Zero with Spring + GraalVM or WebAssembly", listOf(Speaker("Sébastien", "Deleuze")))),
        Room.ADMINISTRATIEZAAL to Slot.Usual(Talk("Declarative Test Setup", listOf(Speaker("Brian", "Norman")))),
        Room.BERLAGE_ZAAL to Slot.Usual(Talk("Adventures building a Kotlin Multiplatform Benchmarking Library", listOf(Speaker("Rahul", "Ravikumar")))),
        Room.MENDES_DA_COSTA_KAMER to Slot.AwsLab(Talk("Adding Storage", listOf(Speaker("Anshul", "Gupta"), Speaker("Muhammed", "Salih Guler"), Speaker("Tyler", "Roach"))))
    )

val day2_seventh_row =
    mapOf(
        Room.EFFECTENBEURSZAAL to Slot.Usual(Talk("The Changing Grain of Kotlin", listOf(Speaker("Nat", "Pryce"), Speaker("Duncan", "McGregor")))),
        Room.GRAANBEURSZAAL to Slot.Usual(Talk("Kotlin likes to Demoparty", listOf(Speaker("Etienne", "Caron")))),
        Room.ADMINISTRATIEZAAL to Slot.Usual(Talk("Meetup with KMM Libraries", listOf(Speaker("Colin", "Lee")))),
        Room.BERLAGE_ZAAL to Slot.Usual(Talk("Transforming Farmer's Lives Using Android in Kenya", listOf(Speaker("Harun", "Wangereka")))),
        Room.VERWEY_KAMER to Slot.NerdRanch(Talk("Refactoring for Testability", listOf(Speaker("Ben", "Zweber")))),
    )

/* DAY 2 */

val day3_keynote =
    mapOf(
        Room.EFFECTENBEURSZAAL to Slot.Usual(Talk("Six Impossible Things", listOf(Speaker("Kevlin", "Henney"))))
    )

val day3_first_row =
    mapOf(
        Room.EFFECTENBEURSZAAL to Slot.Usual(Talk("Coroutines and Loom behind the scenes", listOf(Speaker("Roman", "Elizarov")))),
        Room.GRAANBEURSZAAL to Slot.Usual(Talk("You can do desktop too!", listOf(Speaker("Viktor", "Kropp"), Speaker("Sebastian", "Aigner")))),
        Room.ADMINISTRATIEZAAL to Slot.Usual(Talk("AWS SDK for Kotlin overview", listOf(Speaker("Ian", "Botsford")))),
        Room.BERLAGE_ZAAL to Slot.Usual(Talk("K2 Compiler plugins", listOf(Speaker("Mikhail", "Glukhikh")))),
        Room.VEILINGZAAL to Slot.Lightnings(
            ((Moment(10, 15) to Moment(10, 30)) to Talk("How to Publish Your First Kotlin Multiplatform Library", listOf(Speaker("Isuru", "Rajapakse"))))
            to ((Moment(10, 40) to Moment(10, 55)) to Talk("Partial linkage of Kotlin libraries", listOf(Speaker("Dmitriy", "Dolovov"))))
        )
    )

val day3_second_row =
    mapOf(
        Room.EFFECTENBEURSZAAL to Slot.Usual(Talk("Handling billions of events per day with Kotlin Coroutines", listOf(Speaker("Florentin", "Simion"), Speaker("Rares", "Vlasceanu")))),
        Room.GRAANBEURSZAAL to Slot.Usual(Talk("KotlinX Libraries", listOf(Speaker("Anton", "Arhipov"), Speaker("Svetlana", "Isakova")))),
        Room.ADMINISTRATIEZAAL to Slot.Usual(Talk("How we completely rewrote our static code analyzer for Kotlin", listOf(Speaker("Marharyta", "Nedzelska")))),
        Room.BERLAGE_ZAAL to Slot.Usual(Talk("Kotlin/Multiplatform for iOS developers : state & future.", listOf(Speaker("Salomon", "Brys")))),
        Room.VEILINGZAAL to Slot.Lightnings(
            ((Moment(11, 15) to Moment(11, 30)) to Talk("Why code autocompletion works faster on weekends", listOf(Speaker("Egor", "Tolstoy"))))
            to ((Moment(11, 40) to Moment(11, 55)) to Talk("Fighting regressions in Kotlin", listOf(Speaker("Liliia", "Abdulina"))))
        ),
        Room.VERWEY_KAMER to Slot.NerdRanch(Talk("Compose for Desktop", listOf(Speaker("Bryan", "Sills")))),
        Room.MENDES_DA_COSTA_KAMER to Slot.AwsLab(Talk("Adding Datastore with GraphQL API I", listOf(Speaker("Anshul", "Gupta"), Speaker("Tyler", "Roach"), Speaker("Muhammed", "Salih Guler"))))
    )

val day3_third_row =
    mapOf(
        Room.EFFECTENBEURSZAAL to Slot.Usual(Talk("Evolving your Kotlin API painlessly for clients", listOf(Speaker("Leonid", "Startsev")))),
        Room.GRAANBEURSZAAL to Slot.Usual(Talk("Kotlin Multiplatform Mobile for Teams", listOf(Speaker("Kevin", "Galligan")))),
        Room.ADMINISTRATIEZAAL to Slot.Usual(Talk("How we’re improving performance of IntelliJ Kotlin plugin", listOf(Speaker("Vladimir", "Dolzhenko")))),
        Room.BERLAGE_ZAAL to Slot.Usual(Talk("Deep Dive into Deep Learning with KotlinDL", listOf(Speaker("Alexey", "Zinoviev")))),
        Room.VEILINGZAAL to Slot.Lightnings(
            ((Moment(13,  0) to Moment(13, 15)) to Talk("Tracing coroutines in the JVM", listOf(Speaker("Tyson", "Henning"))))
            to ((Moment(13, 25) to Moment(13, 40)) to Talk("Preventing Data Races in Async Coroutines", listOf(Speaker("Kevin", "Bierhoff"))))
        ),
        Room.VERWEY_KAMER to Slot.NerdRanch(Talk("Introduction to Context Receivers", listOf(Speaker("Bryan", "Sills")))),
    )

val day3_fourth_row =
    mapOf(
        Room.EFFECTENBEURSZAAL to Slot.Usual(Talk("Level up on Kotlin Multiplatform Mobile", listOf(Speaker("Pamela", "Hill")))),
        Room.GRAANBEURSZAAL to Slot.Usual(Talk("Supercharge your GraphQL with Ktor and GraalVM", listOf(Speaker("Derek", "Kuc")))),
        Room.ADMINISTRATIEZAAL to Slot.Usual(Talk("To Detekt 2.0, and beyond!", listOf(Speaker("Nicola", "Corti")))),
        Room.BERLAGE_ZAAL to Slot.Usual(Talk("Make more money by modeling and optimizing your business processes with Kotlin", listOf(Speaker("Holger", "Brandl")))),
        Room.VEILINGZAAL to Slot.Lightnings(
            ((Moment(14,  0) to Moment(14, 15)) to Talk("Avoiding common coroutines mistakes in Compose", listOf(Speaker("Márton", "Braun"))))
            to ((Moment(14, 25) to Moment(14, 40)) to Talk("World of Warcraft bot with process hacking using Kotlin/Native", listOf(Speaker("Ignat", "Beresnev"))))
        ),
        Room.MENDES_DA_COSTA_KAMER to Slot.AwsLab(Talk("Adding Datastore with GraphQL API II", listOf(Speaker("Anshul", "Gupta"), Speaker("Muhammed", "Salih Guler"), Speaker("Tyler", "Roach"))))
    )


val day3_fifth_row =
    mapOf(
        Room.EFFECTENBEURSZAAL to Slot.Usual(Talk("Playing in the Treehouse with Redwood and Zipline", listOf(Speaker("Jake", "Wharton")))),
        Room.GRAANBEURSZAAL to Slot.Usual(Talk("One language to build them all!", listOf(Speaker("Paul", "Merlin"), Speaker("Rodrigo", "Oliveira")))),
        Room.ADMINISTRATIEZAAL to Slot.Usual(Talk("Koin 3.2 - Improving the Kotlin Developer Experience", listOf(Speaker("Arnaud", "Giuliani")))),
        Room.BERLAGE_ZAAL to Slot.Usual(Talk("Kotlin Native for CLIs", listOf(Speaker("Ryan", "Harter")))),
        Room.VERWEY_KAMER to Slot.NerdRanch(Talk("Refactoring for Testability", listOf(Speaker("Ben", "Zweber")))),
        Room.MENDES_DA_COSTA_KAMER to Slot.AwsLab(Talk("Adding Geo", listOf(Speaker("Anshul", "Gupta"), Speaker("Muhammed", "Salih Guler"), Speaker("Tyler", "Roach"))))
    )


val day3_sixth_row =
    mapOf(
        Room.EFFECTENBEURSZAAL to Slot.Usual(Talk("Gilded Rose Refactoring Kata", listOf(Speaker("Dmitry", "Kandalov")))),
        Room.GRAANBEURSZAAL to Slot.Usual(Talk("Modern Compose Architecture with Circuit", listOf(Speaker("Zac", "Sweers"), Speaker("Kieran", "Elliott")))),
        Room.ADMINISTRATIEZAAL to Slot.Usual(Talk("Direct2DB: Radically simplified app architecture with Kotlin", listOf(Speaker("Mike", "Hearn")))),
        Room.BERLAGE_ZAAL to Slot.Usual(Talk("Implementing a MIDI player in Kotlin", listOf(Speaker("Piotr", "Jagielski")))),
    )


val day3_closing =
    mapOf(
        Room.EFFECTENBEURSZAAL to Slot.Usual(Talk("Closing Panel", listOf(Speaker("Hadi", "Hariri"))))
    )

/* SCHEDULE */

val schedule : Schedule = listOf(
    Day(
        Date(12, 4),
        mapOf(
            (Moment( 8,  0) to Moment( 9,  0)) to Row.IsSpecial(Special.BREAKFAST),
            (Moment( 9,  0) to Moment(10, 30)) to Row.Slots(workshops(1)),
            (Moment(10, 30) to Moment(11,  0)) to Row.IsSpecial(Special.BREAK),
            (Moment(11,  0) to Moment(12, 30)) to Row.Slots(workshops(2)),
            (Moment(12, 30) to Moment(13,  0)) to Row.IsSpecial(Special.LUNCH),
            (Moment(13, 30) to Moment(14, 55)) to Row.Slots(workshops(3)),
            (Moment(14, 55) to Moment(15, 25)) to Row.IsSpecial(Special.BREAK),
            (Moment(15, 30) to Moment(17,  0)) to Row.Slots(workshops(4)),
            //(Moment(17,  0) to Moment(23, 55)) to Row.IsSpecial(Special.END_OF_TALKS),
        )
    ),
    Day(
        Date(13, 4),
        mapOf(
            (Moment( 9,  0) to Moment(10,  0)) to Row.Slots(day2_keynote),
            //(Moment(10,  0) to Moment(10, 15)) to Row.IsSpecial(Special.BREAK),
            (Moment(10, 15) to Moment(11,  0)) to Row.Slots(day2_first_row),
            //(Moment(11,  0) to Moment(11, 15)) to Row.IsSpecial(Special.BREAK),
            (Moment(11, 15) to Moment(12,  0)) to Row.Slots(day2_second_row),
            (Moment(12,  0) to Moment(13,  0)) to Row.IsSpecial(Special.LUNCH),
            (Moment(13,  0) to Moment(13, 45)) to Row.Slots(day2_third_row),
            //(Moment(13, 45) to Moment(14,  0)) to Row.IsSpecial(Special.BREAK),
            (Moment(14,  0) to Moment(14, 45)) to Row.Slots(day2_fourth_row),
            (Moment(14, 45) to Moment(15, 15)) to Row.IsSpecial(Special.COFFEE_BREAK),
            (Moment(15, 15) to Moment(16,  0)) to Row.Slots(day2_fifth_row),
            //(Moment(16,  0) to Moment(16, 15)) to Row.IsSpecial(Special.BREAK),
            (Moment(16, 15) to Moment(17,  0)) to Row.Slots(day2_sixth_row),
            //(Moment(17,  0) to Moment(17, 15)) to Row.IsSpecial(Special.BREAK),
            (Moment(17, 15) to Moment(18,  0)) to Row.Slots(day2_seventh_row),
            (Moment(18,  0) to Moment(21,  0)) to Row.IsSpecial(Special.PARTY),
            //(Moment(21,  0) to Moment(23, 55)) to Row.IsSpecial(Special.END_OF_TALKS),
        )
    ),
    Day(
        Date(14, 4),
        mapOf(
            (Moment( 9,  0) to Moment(10,  0)) to Row.Slots(day3_keynote),
            //(Moment(10,  0) to Moment(10, 15)) to Row.IsSpecial(Special.BREAK),
            (Moment(10, 15) to Moment(11,  0)) to Row.Slots(day3_first_row),
            //(Moment(11,  0) to Moment(11, 15)) to Row.IsSpecial(Special.BREAK),
            (Moment(11, 15) to Moment(12,  0)) to Row.Slots(day3_second_row),
            (Moment(12,  0) to Moment(13,  0)) to Row.IsSpecial(Special.LUNCH),
            (Moment(13,  0) to Moment(13, 45)) to Row.Slots(day3_third_row),
            //(Moment(13, 45) to Moment(14,  0)) to Row.IsSpecial(Special.BREAK),
            (Moment(14,  0) to Moment(14, 45)) to Row.Slots(day3_fourth_row),
            (Moment(14, 45) to Moment(15, 15)) to Row.IsSpecial(Special.COFFEE_BREAK),
            (Moment(15, 15) to Moment(16,  0)) to Row.Slots(day3_fifth_row),
            //(Moment(16,  0) to Moment(16, 15)) to Row.IsSpecial(Special.BREAK),
            (Moment(16, 15) to Moment(17,  0)) to Row.Slots(day3_sixth_row),
            //(Moment(17,  0) to Moment(17, 15)) to Row.IsSpecial(Special.BREAK),
            (Moment(17, 15) to Moment(18, 15)) to Row.Slots(day3_closing),
            //(Moment(18,  15) to Moment(23, 55)) to Row.IsSpecial(Special.END_OF_TALKS),
        )
    )
)